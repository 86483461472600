<template>
  <vue-final-modal
    class="layout-modal"
    :class="modalClass"
    :modelValue="showModal"
    @clickOutside="emit('closeModal')"
    clickToClose
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="scroll">
      <header class="layout-modal__header">
        <div class="header">
          <button-modal-close @close="emit('closeModal')" />
          <slot name="header" />
        </div>
      </header>
      <main class="layout-modal__main">
        <slot />
      </main>
      <footer class="layout-modal__footer">
        <slot name="footer" />
      </footer>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  defineProps<{
    showModal: boolean,
    modalClass?: string
  }>();

  const emit = defineEmits(['closeModal']);

</script>

<style src="~/assets/styles/components/layout/modal.scss" lang="scss" />
